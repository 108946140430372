import React from "react";

import { Container, Icon, Button, Text } from "@atoms";
import { SocialLinks } from "@molecules";
import { AppLink } from "@base";
import dayjs from "dayjs";

const RomeroFooter = ({ nav }) => {
  return (
    <div className="mt-auto w-full bg-blue-dark py-8 px-2 sm:py-12 sm:px-0">
      <Container>
        <div className="flex flex-col items-center md:flex-row md:flex-nowrap md:items-start md:justify-between">
          {/* logo */}
          <div className="order-3 mt-8 block md:order-1 md:mt-0">
            <Icon
              name="romeroLogoVertical"
              className="h-auto w-16 md:w-[5.25rem]"
            />
          </div>
          {/* nav */}
          <div className="order-1 w-full md:order-2 md:w-1/2">
            {/* tablet + desktop */}
            <div className="grid grid-cols-2 gap-4 text-center text-xs text-white sm:grid-cols-3 md:grid-cols-4 md:text-left">
              {nav &&
                nav.map(item => {
                  const { uid, type, link } = item;
                  switch (type) {
                    case "largeLink":
                      return (
                        <div key={uid}>
                          <AppLink
                            to={link.link}
                            className="font-medium hover:text-gold"
                          >
                            {link.text}
                          </AppLink>
                        </div>
                      );
                    case "button":
                      return (
                        <div key={uid}>
                          <Button size="xs" to={link.link}>
                            {link.text}
                          </Button>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
            </div>
            {/* copyright */}
            <div className="mt-12 hidden flex-shrink-0 text-xxs text-white md:block">
              {/* TODO: Pull from globals */}
              <AppLink
                className="mb-1 inline-block font-medium hover:text-gold"
                to="/privacy"
              >
                Privacy Policy
              </AppLink>
              <br />
              &copy; {dayjs().format("YYYY")} Romero Institute. The 501(c)(3)
              Romero Institute is a law and public policy center.
            </div>
          </div>
          {/* details */}
          <div className="order-2 mt-8 flex-shrink-0 text-white md:order-3 md:mt-0">
            <div className="w-full">
              <SocialLinks className="text-blue-dark" />
            </div>
            <div className="mt-4 text-center md:text-left">
              <Text variant="xs" className="font-medium leading-normal">
                {"210 High St, Second Floor<br>Santa Cruz, CA 95060"}
              </Text>
            </div>
            <div className="mt-4">
              {nav &&
                nav.map(item => {
                  const { uid, type, link } = item;
                  switch (type) {
                    case "smallLink":
                      return (
                        <div
                          key={uid}
                          className="mt-2 w-full text-center text-xs font-medium md:text-left"
                        >
                          <AppLink to={link.link} className="hover:text-gold">
                            {link.text}
                          </AppLink>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
            </div>
          </div>
          {/* mobile copyright */}
        </div>
        <div className="mt-8 block flex-shrink-0 text-center text-xxs text-white md:mt-12 md:hidden">
          {/* TODO: Pull from globals */}
          <AppLink
            className="mb-1 inline-block font-medium hover:text-gold"
            to="/privacy"
          >
            Privacy Policy
          </AppLink>
          <br />
          &copy; {dayjs().format("YYYY")} Romero Institute. The 501(c)(3) Romero
          Institute is a law and public policy center.
        </div>
      </Container>
    </div>
  );
};

RomeroFooter.defaultProps = {};

export default RomeroFooter;
